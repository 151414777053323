import React, { Component } from 'react'
import { Layout, Divider, Typography, Space } from 'antd'

import I18n from '../../../I18n/I18n'
import AppConfig from '../../../Config/AppConfig'
import BrandStrings from '../../../Themes/BrandStrings'

// import Log from '../../../Utils/Log'
// const log = new Log('Home')

const { Content } = Layout
const { Title } = Typography

// Styles
const videoFrameStyle = {
  paddingTop: 35,
  justifyContent: 'center',
  width: '100%'
}

class Home extends Component {
  render () {
    return (
      <Content>
        <Title level={2}>
          {I18n.t('Home.title', {
            branding: BrandStrings.general('customerName')
          })}
        </Title>
        <Divider />
        {I18n.t('Home.infoText')}
        <br />

        <br />
        {I18n.t('Home.watchIntroVideo')}
        <Divider />
        <Space style={videoFrameStyle}>
          <iframe
            width='784'
            height='441'
            src={
              AppConfig.media.introductionVideoURL[AppConfig.project.branding]
            }
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowFullScreen
          ></iframe>
        </Space>
      </Content>
    )
  }
}

export default Home
