import React, { Component } from 'react'
import { Layout, Typography, Button, Space, Divider } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

import I18n from '../../../I18n/I18n'
import AppConfig from '../../../Config/AppConfig'
import { getTeamBasics, getToken } from '../../../Services/ServerAccessService'
import BrandStrings from '../../../Themes/BrandStrings'

import Log from '../../../Utils/Log'
const log = new Log('Components/Dashboard/Content/Export')

const { Content } = Layout
const { Title, Text } = Typography

// Styles
const exportButtonStyle = {
  paddingTop: 25,
  justifyContent: 'center',
  width: '100%'
}

class Export extends Component {
  handleDownload = async () => {
    const teamBasics = await getTeamBasics()
    const token = getToken()
    log.debug(teamBasics)
    log.debug(
      'Handle analytics download with token',
      token,
      'from coaching',
      teamBasics.intervention.objectId.objectId
    )
    document.getElementById('downloadFormToken').value = token
    const form = document.getElementById('downloadForm')
    form.action =
      AppConfig.serverAccess.pmcpRestURL +
      'v04/pcms/downloadInterventionAnalyticsViaForm/' +
      teamBasics.intervention.objectId.objectId
    form.submit()
  }

  render () {
    return (
      <Content>
        <Title level={2}>{I18n.t('Export.title')}</Title>
        <Divider />
        <Space direction='vertical'>
          <Text>
            {I18n.t('Export.exportText', {
              branding: BrandStrings.general('customerName')
            })}
          </Text>
          <form
            id='downloadForm'
            style={{ display: 'none' }}
            action=''
            target='_blank'
            method='POST'
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              type='hidden'
              name='Authorization'
              id='downloadFormToken'
              value=''
            />
            <input type='submit' style={{ visibility: 'hidden' }} />
          </form>
        </Space>
        <Space style={exportButtonStyle}>
          <Button type='primary' onClick={this.handleDownload}>
            <DownloadOutlined />
            {I18n.t('Export.buttonText')}
          </Button>
        </Space>
      </Content>
    )
  }
}

export default Export
