import React, { Component } from 'react'
import { Layout, Typography, Button, Space, Divider } from 'antd'
import { LoginOutlined } from '@ant-design/icons'

import I18n from '../../../I18n/I18n'
import AppConfig from '../../../Config/AppConfig'
import { getToken } from '../../../Services/ServerAccessService'
import BrandStrings from '../../../Themes/BrandStrings'

import Log from '../../../Utils/Log'
const log = new Log('Components/Dashboard/Content/Coaching')

const { Content } = Layout
const { Title, Text } = Typography

// Styles
const jumpButtonStyle = {
  paddingTop: 25,
  justifyContent: 'center',
  width: '100%'
}

class Account extends Component {
  openPMCP = () => {
    const token = getToken()

    log.debug('Open PMCP with token', token)
    document.getElementById('loginFormToken').value = token
    document.getElementById('loginForm').submit()
  }

  render () {
    return (
      <Content>
        <Title level={2}>{I18n.t('Account.title')}</Title>
        <Divider />
        <Space direction='vertical'>
          <Text>
            {I18n.t('Account.accountText', {
              branding: BrandStrings.general('customerName')
            })}
          </Text>
          <form
            id='loginForm'
            style={{ display: 'none' }}
            action={AppConfig.serverAccess.pmcpURL}
            target='_blank'
            method='POST'
            onSubmit={(e) => e.preventDefault()}
          >
            <input type='hidden' name='jwt' id='loginFormToken' value='' />
            <input type='submit' style={{ visibility: 'hidden' }} />
          </form>
        </Space>
        <Space style={jumpButtonStyle}>
          <Button type='primary' onClick={this.openPMCP}>
            <LoginOutlined />
            {I18n.t('Coaching.buttonText')}
          </Button>
        </Space>
      </Content>
    )
  }
}

export default Account
