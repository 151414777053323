import React, { Component } from 'react'
import {
  App,
  Layout,
  Typography,
  Button,
  Space,
  Divider,
  Alert,
  Upload
} from 'antd'
import { CloudDownloadOutlined, CloudUploadOutlined } from '@ant-design/icons'

import I18n from '../../../I18n/I18n'
import AppConfig from '../../../Config/AppConfig'
import { getToken, setToken } from '../../../Services/ServerAccessService'
import BrandStrings from '../../../Themes/BrandStrings'

import Log from '../../../Utils/Log'
const log = new Log('Components/Dashboard/Content/Transfer')

const { Content } = Layout
const { Title, Text } = Typography

// Styles
const transferButtonStyle = {
  paddingTop: 25,
  justifyContent: 'center',
  width: '100%'
}

function withHooks (WrappedComponent) {
  return function (props) {
    const app = App.useApp()

    return <WrappedComponent app={app} {...props} />
  }
}

class Transfer extends Component {
  handleDownload = () => {
    const token = getToken()

    log.debug('Handle export download with token', token)
    document.getElementById('downloadFormToken').value = token
    const form = document.getElementById('downloadForm')
    form.action = AppConfig.serverAccess.restURL + 'v03/team/downloadTeamExport'
    form.submit()
  }

  beforeUpload = (file) => {
    const { message } = this.props.app

    const isPCMSExport = file.name.toLowerCase().endsWith('.pcms')
    if (!isPCMSExport) {
      message.error(
        I18n.t('Transfer.uploadFormatError', {
          branding: BrandStrings.general('customerName')
        })
      )
    }

    const isLt500M = file.size / 1024 / 1024 < 500
    if (!isLt500M) {
      message.error(I18n.t('Transfer.uploadSizeError'))
    }

    return isPCMSExport && isLt500M
  }

  onUploadChange = (info) => {
    const { message } = this.props.app

    if (info.file.status === 'done') {
      log.debug('success')

      setToken(info.file.response.token)

      message.success(
        I18n.t('Transfer.uploadAndImportSuccessful', { file: info.file.name })
      )
    } else if (info.file.status === 'error') {
      log.debug('error')
      message.error(I18n.t('Transfer.uploadFailed', { file: info.file.name }))
    }
  }

  render () {
    return (
      <Content>
        <Title level={2}>{I18n.t('Transfer.title')}</Title>
        <Divider />
        <Space direction='vertical'>
          <Text>
            {I18n.t('Transfer.transferText', {
              branding: BrandStrings.general('customerName')
            })}
          </Text>
          <br />
          <Alert
            message={I18n.t('Transfer.transferWarning')}
            type='warning'
            showIcon
          />
          <form
            id='downloadForm'
            style={{ display: 'none' }}
            action=''
            target='_blank'
            method='POST'
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              type='hidden'
              name='Authorization'
              id='downloadFormToken'
              value=''
            />
            <input type='submit' style={{ visibility: 'hidden' }} />
          </form>
        </Space>
        <Space style={transferButtonStyle}>
          <Button type='primary' onClick={this.handleDownload}>
            <CloudDownloadOutlined />
            {I18n.t('Transfer.exportButtonText')}
          </Button>
        </Space>
        <Space style={transferButtonStyle}>
          <Upload
            action={
              AppConfig.serverAccess.restURL + 'v03/team/uploadTeamExport'
            }
            headers={{
              authorization: getToken()
            }}
            beforeUpload={this.beforeUpload}
            onChange={this.onUploadChange}
          >
            <Button type='primary'>
              <CloudUploadOutlined />
              {I18n.t('Transfer.importButtonText')}
            </Button>
          </Upload>
        </Space>
      </Content>
    )
  }
}

export default withHooks(Transfer)
