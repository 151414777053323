import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  App,
  Layout,
  Space,
  Row,
  Input,
  Button,
  Select,
  Typography,
  Tabs,
  Divider
} from 'antd'
import { ContactsOutlined } from '@ant-design/icons'

import I18n from '../../../I18n/I18n'
import GUIActions from '../../../Redux/GUIRedux'
import {
  getTeamAvatars,
  updateTeamAvatars
} from '../../../Services/ServerAccessService'
import MediaLibrary from '../Generic/MediaLibrary'
import { isBlank } from '../../../Utils/Helpers'

import Log from '../../../Utils/Log'
const log = new Log('Components/Dashboard/Content/Coach')

const { Content } = Layout
const { Title, Text } = Typography
const { Option } = Select

// Styles
const titleContainerStyle = {
  display: 'flex',
  marginBottom: -14
}

const titleStyle = { flex: 1 }

const saveButtonStyle = {
  marginTop: 30,
  marginLeft: 10,
  paddingLeft: 30,
  paddingRight: 30,
  flex: 0
}

const imageSelectStyle = { width: '100%', height: 120 }

const imageOptionStyle = { justifyContent: 'center', verticalAlign: 'middle' }

const imageOptionStyleImageWrapper = {
  height: 100,
  width: 100,
  verticalAlign: 'middle',
  objectFit: 'contain',
  display: 'inline-block'
}

const imageOptionStyleImage = {
  width: '100%',
  height: '100%',
  verticalAlign: 'middle',
  display: 'block',
  objectFit: 'contain'
}

class Avatar extends Component {
  renderImageSelectOptions (mediaURL, mediums) {
    const options = []

    for (const medium of mediums) {
      options.push(
        <Option key={medium.uid} value={medium.uid} style={imageOptionStyle}>
          <div style={imageOptionStyleImageWrapper}>
            <img
              src={mediaURL + medium.uid}
              alt=''
              style={imageOptionStyleImage}
            />
          </div>
          &nbsp;&nbsp; ID: <strong>{medium.uid}</strong> ({medium.name})
        </Option>
      )
    }

    return options
  }

  render () {
    const {
      index,
      avatar,
      mediaURL,
      mediums,
      updateAvatarName,
      updateAvatarImage
    } = this.props

    return (
      <Space
        direction='vertical'
        size='middle'
        style={{
          display: 'flex'
        }}
      >
        <Row>
          <Text>{I18n.t('Coach.nameLabel')}</Text>
          <Input
            placeholder={I18n.t('Coach.namePlaceholder')}
            defaultValue={avatar.name}
            prefix={<ContactsOutlined />}
            onBlur={(e) => {
              updateAvatarName(index, e.target.value)
            }}
          />
        </Row>
        <Row>
          <Text style={{ textAlign: 'start' }}>
            {I18n.t('Coach.chooseImageLabel')}
          </Text>
          <Select
            style={imageSelectStyle}
            placeholder={I18n.t('Coach.chooseImagePlaceholder')}
            defaultValue={avatar.image}
            onSelect={(key, imageSelection) => {
              updateAvatarImage(index, imageSelection)
            }}
          >
            {this.renderImageSelectOptions(mediaURL, mediums)}
          </Select>
        </Row>
      </Space>
    )
  }
}

function withHooks (WrappedComponent) {
  return function (props) {
    const app = App.useApp()

    return <WrappedComponent app={app} {...props} />
  }
}

class Coach extends Component {
  constructor (props) {
    super(props)

    this.syncMedia = this.syncMedia.bind(this)
    this.updateAvatarName = this.updateAvatarName.bind(this)
    this.updateAvatarImage = this.updateAvatarImage.bind(this)

    this.increasingRandom = 0

    // Only default values must be defined (with null)
    this.state = {
      _lastUpdated: 0,
      avatars: [],
      savedUsed: [],
      activeTab: '',
      mediaURL: null,
      mediums: []
    }
  }

  async componentDidMount () {
    await this.getAvatars()
  }

  setState (newState, newSaved = undefined) {
    super.setState(newState)

    const { saved, setSaved } = this.props

    if (newSaved !== undefined && saved !== newSaved) {
      setSaved(newSaved)
    }
  }

  // Get data from server
  async getAvatars () {
    const result = await getTeamAvatars()

    log.debug('Get result:', result)

    const newAvatars = []

    for (const avatar of result.avatars) {
      newAvatars.push({ ...avatar, random: this.increasingRandom++ })
    }

    this.setState(
      {
        ...result,
        avatars: newAvatars,
        activeTab:
          result.avatars.length > 0
            ? `avatarTab_0_${newAvatars[0].random}`
            : '',
        savedUsed: newAvatars.map((avatar) => avatar.image)
      },
      true
    )
  }

  // Update data on server
  async updateAvatars () {
    const { _lastUpdated, avatars } = this.state

    // Update object
    const update = {
      _lastUpdated,
      avatars: avatars.map((avatar) => {
        const newAvatar = { ...avatar }
        delete newAvatar.random
        return newAvatar
      })
    }
    log.debug('Update object:', update)

    const result = await updateTeamAvatars(update)

    log.debug('Update result:', result)

    if (result === null) {
      // Unknown error
      this.props.app.message.error(I18n.t('Common.remoteSyncError'))
    } else if (result === undefined) {
      // Inconsistent
      this.props.app.message.warning(I18n.t('Common.remoteSyncInconsistent'))

      await this.getAvatars()
    } else {
      // Success
      this.props.app.message.success(I18n.t('Common.remoteSyncSaved'))

      const newAvatars = []

      for (const avatar of result.avatars) {
        newAvatars.push({ ...avatar, random: this.increasingRandom++ })
      }

      this.setState(
        {
          ...result,
          avatars: newAvatars,
          activeTab:
            result.avatars.length > 0
              ? `avatarTab_0_${newAvatars[0].random}`
              : '',
          savedUsed: newAvatars.map((avatar) => avatar.image)
        },
        true
      )
    }
  }

  // Sync media with library
  syncMedia (syncedMedia) {
    const { mediaURL, mediums } = syncedMedia

    this.setState({
      mediaURL,
      mediums
    })
  }

  addAvatarTab () {
    const { avatars } = this.state

    const newAvatar = {
      random: this.increasingRandom++,
      name: '',
      image: null
    }
    const newAvatars = [...avatars, newAvatar]

    const newActiveTab = `avatarTab_${newAvatars.length - 1}_${
      newAvatar.random
    }`

    this.setState(
      {
        avatars: newAvatars,
        activeTab: newActiveTab
      },
      false
    )
  }

  removeAvatarTab (targetTab) {
    const { avatars, activeTab } = this.state

    // At least one avatar shall exist
    if (avatars.length === 1) {
      return false
    }

    const removeIndex = parseInt(targetTab.split('_')[1])
    let currentIndex = parseInt(activeTab.split('_')[1])

    const newAvatars = [...avatars]
    newAvatars.splice(removeIndex, 1)

    let newIndex = -1
    if (currentIndex > newAvatars.length - 1) {
      newIndex = newAvatars.length - 1
    } else {
      newIndex = currentIndex
    }

    this.setState(
      {
        avatars: newAvatars,
        activeTab:
          newIndex > -1
            ? `avatarTab_${newIndex}_${newAvatars[newIndex].random}`
            : ''
      },
      false
    )
  }

  handleTabChange = (activeTab) => {
    log.debug('Switched to tab:', activeTab)
    this.setState({ activeTab })
  }

  handleTabEdit = (targetTab, action) => {
    if (action === 'add') {
      this.addAvatarTab()
    } else {
      this.removeAvatarTab(targetTab)
    }
  }

  updateAvatarName (index, value) {
    const { avatars } = this.state

    const newAvatars = [...avatars]
    newAvatars[index].name = value

    this.setState({ avatars: newAvatars }, false)
  }

  updateAvatarImage (index, value) {
    const { avatars } = this.state

    const newAvatars = [...avatars]
    newAvatars[index].image = value.value

    this.setState({ avatars: newAvatars }, false)
  }

  render () {
    const { saved } = this.props
    const { _lastUpdated, avatars, activeTab, savedUsed, mediaURL, mediums } =
      this.state

    const hideAdd = avatars.length >= 6

    if (_lastUpdated > 0) {
      const used = [...savedUsed, ...avatars.map((avatar) => avatar.image)]
      log.debug(JSON.stringify(used))

      return (
        <Content>
          <Row style={titleContainerStyle}>
            <Title style={titleStyle} level={2}>
              {I18n.t('Coach.title')}
            </Title>
            <Button
              type={saved ? 'default' : 'primary'}
              style={saveButtonStyle}
              onClick={() => {
                this.updateAvatars()
              }}
            >
              {I18n.t('Common.save')}
            </Button>
          </Row>

          <Divider />
          <Title level={3}>{I18n.t('Coach.configureCoaches')}</Title>
          <Tabs
            type='editable-card'
            onChange={this.handleTabChange}
            onEdit={this.handleTabEdit}
            hideAdd={hideAdd}
            activeKey={activeTab}
            items={avatars.map((avatar, index) => ({
              label:
                'Coach ' +
                (isBlank(avatar.name) ? index + 1 : '"' + avatar.name + '"'),
              children: (
                <Avatar
                  index={index}
                  avatar={avatar}
                  mediaURL={mediaURL}
                  mediums={mediums}
                  updateAvatarName={this.updateAvatarName}
                  updateAvatarImage={this.updateAvatarImage}
                />
              ),
              key: `avatarTab_${index}_${avatar.random}`
            }))}
          />
          <Divider />
          <Title level={3}>{I18n.t('Coach.manageCoaches')}</Title>
          <MediaLibrary
            used={used}
            purpose={'AVATARS'}
            syncMedia={this.syncMedia}
          />
        </Content>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = (state) => ({
  saved: state.guiState.saved
})

const mapStateToDispatch = (dispatch) => ({
  setSaved: (saved) => {
    dispatch(GUIActions.setSaved(saved))
  }
})

export default connect(mapStateToProps, mapStateToDispatch)(withHooks(Coach))
