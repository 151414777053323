import React, { Component } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { App, Modal, Upload } from 'antd'

import I18n from '../../../I18n/I18n'
import AppConfig from '../../../Config/AppConfig'
import {
  getToken,
  getMediaList,
  deleteMedium
} from '../../../Services/ServerAccessService'

import Log from '../../../Utils/Log'
const log = new Log('Components/Dashboard/Generic/MediaLibrary')

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

function withHooks (WrappedComponent) {
  return function (props) {
    const app = App.useApp()

    return <WrappedComponent app={app} {...props} />
  }
}

class MediaLibrary extends Component {
  static defaultProps = {
    used: [],
    purpose: 'LIBRARY',
    syncMedia: null
  }

  constructor (props) {
    super(props)

    this.state = {
      mediaURL: null,
      previewOpen: '',
      previewImage: '',
      previewTitle: '',
      fileList: []
    }
  }

  async componentDidMount () {
    await this.getMedia()
  }

  // Get data from server
  async getMedia () {
    const { purpose } = this.props

    const result = await getMediaList(purpose)

    log.debug('Get result:', result)

    const fileList = []

    for (const medium of result.mediums) {
      fileList.push({
        uid: medium.uid,
        name: medium.name,
        status: 'done',
        url: result.mediaURL + medium.uid
      })
    }

    const stateUpdate = {
      mediaURL: result.mediaURL,
      fileList
    }
    this.setState(stateUpdate)
    this.syncWithParents(stateUpdate)
  }

  syncWithParents (update) {
    const { syncMedia } = this.props
    const { mediaURL, fileList } = update

    if (syncMedia !== null) {
      const mediums = []

      for (const item of fileList) {
        if (item.status === 'done') {
          mediums.push({
            uid: item.uid,
            name: item.name,
            mimeType: item.mimeType
          })
        }
      }

      syncMedia({
        mediaURL,
        mediums
      })
    }
  }

  async deleteMedium (mediumId) {
    const result = await deleteMedium(mediumId)

    log.debug('Delete result:', result)

    if (result === null) {
      // Unknown error
      this.props.app.message.error(I18n.t('Common.remoteSyncError'))
    } else if (result === undefined) {
      // Inconsistent
      this.props.app.message.warning(I18n.t('Common.remoteSyncInconsistent'))

      await this.getMedia()
    } else {
      // Success
      this.props.app.message.success(I18n.t('Common.remoteSyncSuccess'))
    }
  }

  // Preview
  handleOpenPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    this.setState({
      previewOpen: true,
      previewImage: file.url || file.preview,
      previewTitle: file.uid ? `ID: ${file.uid} (${file.name})` : file.name
    })
  }

  handleCancelPreview = () => this.setState({ previewOpen: false })

  // File upload
  handleChange = (info) => {
    const { mediaURL } = this.state
    const { message } = this.props.app

    const newFileList = info.fileList

    if (info.file.status === 'done') {
      log.debug('success')
      message.success(
        I18n.t('MediaLibrary.uploadSuccessful', { file: info.file.name })
      )

      for (const item of newFileList) {
        if (item.uid === info.file.uid) {
          item.uid = info.file.response
          item.url = mediaURL + info.file.response
          break
        }
      }
    } else if (info.file.status === 'error') {
      log.debug('error')
      message.error(
        I18n.t('MediaLibrary.uploadFailed', { file: info.file.name })
      )
    }

    const stateUpdate = { mediaURL, fileList: newFileList }
    this.setState(stateUpdate)
    this.syncWithParents(stateUpdate)
  }

  // File remove
  handleRemove = async (info) => {
    const { used } = this.props
    const { message } = this.props.app

    if (info.percent === 0) {
      return true
    }

    if (used.includes(info.uid)) {
      message.warning(I18n.t('MediaLibrary.inUseError', { id: info.uid }))
      return false
    }

    await this.deleteMedium(info.uid)
  }

  // Pre-upload check
  beforeUpload = (file) => {
    const { message } = this.props.app

    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error(I18n.t('MediaLibrary.imageFormatError'))
    }

    const isLt5M = file.size / 1024 / 1024 < 5
    if (!isLt5M) {
      message.error(I18n.t('MediaLibrary.imageSizeError'))
    }

    return isJpgOrPng && isLt5M
  }

  render () {
    const { mediaURL, previewOpen, previewImage, previewTitle, fileList } =
      this.state
    const { purpose } = this.props

    if (mediaURL != null) {
      return (
        <>
          <Upload
            multiple
            action={
              AppConfig.serverAccess.restURL + 'v03/media/upload/' + purpose
            }
            headers={{
              authorization: getToken()
            }}
            listType={purpose === 'AVATARS' ? 'picture-circle' : 'picture-card'}
            fileList={fileList}
            showUploadList={{
              showDownloadIcon: true,
              showRemoveIcon: true
            }}
            onPreview={this.handleOpenPreview}
            onChange={this.handleChange}
            onRemove={this.handleRemove}
            beforeUpload={this.beforeUpload}
          >
            <button
              style={{
                border: 0,
                background: 'none'
              }}
              type='button'
            >
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8
                }}
              >
                {I18n.t('MediaLibrary.upload')}
              </div>
            </button>
          </Upload>
          <Modal
            open={previewOpen}
            title={previewTitle}
            footer={null}
            onCancel={this.handleCancelPreview}
          >
            <img
              style={{
                width: '100%'
              }}
              alt=''
              src={previewImage}
            />
          </Modal>
        </>
      )
    } else {
      return null
    }
  }
}

export default withHooks(MediaLibrary)
